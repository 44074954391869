import React from "react";
import logo from "../assets/images/ft-logo.png";

const Footer = () => {
  return (
    <footer class="footer_container white-text">
      <div class="container">
        <div class="inner text-center animatedParent animateOnce">
          {/* <div class="logo animated fadeInUp"> */}
          <div>
            <a href="#">
              <img width="175" height="54" src={logo} alt="Logo" />
            </a>
          </div>
          <div class="menu_wrapper animated fadeInUp">
            <div class="main_nav">
              <div class="menu_wrap">
                <ul class="d-flex flex-row flex-wrap align-items-center justify-content-center ">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">About Us</a>
                  </li>
                  <li>
                    <a href="/product">Products</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Blog</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="copyright_txt ">
            ©2023 Liquor Warehouse, LLC – All rights reserved
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
