import axios from "axios";
import config from "../config.json";
//import config from "./config.json";

const url = config.REACT_APP_URL;
// const url = "https://liquorwarehouse.in";

//for delete all excel records
const chandExcelCreate = async (param) => {
  return await axios.post(`${url}/products/exceltableinsert`, { ...param });
};

const chandExcelDelete = async (param) => {
  return await axios.delete(`${url}/products/exceltabletruncate`);
};

const fetchSubCategory = async () => {
  return await axios.get(url + "/products/distcategory");
};
const fetchBanner = async () => {
  return await axios.get(url + "/products/banner");
};

//for banner delete
const deleteBanner = async (param) => {
  return await axios.delete(`${url}/products/bannerdelete/${param}`);
};

//for public images file delete
const fileDelete = async (param) => {
  return await axios.delete(`${url}/products/filedelete/${param}`);
};

const fetchProductList = async () => {
  return await axios.get(url + "/products/allprd");
};
const fetchProductDetailList = async (param) => {
  return await axios.get(`${url}/products/subcat/${param}`);
};
const fetchProductSearchist = async (param) => {
  return await axios.get(`${url}/products/prod/${param}`);
};
const fetchBestDeal = async (param) => {
  return await axios.get(`${url}/products/distcountitems`);
};
const fetchCategory = async (param) => {
  return await axios.get(`${url}/products/category`);
};
const fetchHomeSubCategory = async (param) => {
  return await axios.post(`${url}/products/getsubcategory`, { ...param });
};
const fetchHomeSubSubCategory = async (param) => {
  return await axios.post(`${url}/products/get_sub_subcategory`, { ...param });
};
const fetchCountry = async (param) => {
  return await axios.post(`${url}/products/country`, {
    ...param,
  });
};
const fetchGrape = async (param) => {
  return await axios.post(`${url}/products/grape`, {
    ...param,
  });
};
const fetchFilterData = async (param) => {
  return await axios.post(`${url}/products/itemfilterwise`, { ...param });
};
export {
  fetchSubCategory,
  fetchBanner,
  fetchProductList,
  fetchProductDetailList,
  fetchProductSearchist,
  fetchBestDeal,
  fetchCategory,
  fetchHomeSubCategory,
  fetchHomeSubSubCategory,
  fetchCountry,
  fetchGrape,
  fetchFilterData,
  deleteBanner,
  fileDelete,
  chandExcelCreate,
  chandExcelDelete,
};
